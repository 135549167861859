/* Fields */

.field {}

/* Field labels are h2 in Adaptivetheme */
.field .field-label {
  font-size: 1em;
  font-weight: 700;
  line-height: inherit;
  margin: 0;
}
.field-lable-above .field-label {}
.field-lable-inline .field-label {
  display: inline;
}

/* Imagefields */
.field-type-image {}
.field-type-image img {}

/* Use "datetime" when creating new CCK date fields. */
.field-type-datetime {}

/* Filefield field. */
.field-type-filefield {}

/* Node reference field. */
.field-type-nodereference {}

/* User reference field. */
.field-type-userreference {}

/* Number fields. */
.field-type-number-decimal {}
.field-type-number-float   {}
.field-type-number-integer {}

/* Text fields. */
.field-type-text {}
.field-type-text-long {}

/* Term reference fields. */
.field-type-taxonomy-term-reference .field-label {
  font-size: 1em;
}
.field-type-taxonomy-term-reference ul {
  margin: 0;
  padding: 0;
}
.field-type-taxonomy-term-reference ul li {
  display: inline;
  list-style: none;
  padding: 0 10px 0 0;
}

/* Imagefield skinr settings */
.ai-l .field-type-image {
  margin: 4px 20px 10px 0;
}
.ai-r .field-type-image {
  margin: 4px 0 10px 20px;
}
.ai-b,
.ai-b .block-inner,
.ai-b .block-content {}
.ai-b .block-content p,
.ai-b .block-content div.field-type-image {}
